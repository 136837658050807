import '../styles/globals.css';

import type { AppProps } from 'next/app';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { ThemeProvider } from 'styled-components';

import theme from '../src/theme';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <GoogleAnalytics gaMeasurementId='G-205M5NKDY5' trackPageViews={{ ignoreHashChange: true }} />
      <ThemeProvider theme={theme}>
        {/* // eslint-disable-next-line @next/next/no-sync-scripts */}
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}
